import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import useFilterForm from '@vl/hooks/useFilterForm';
import { decode } from '@vl/mod-utils/jwt';
import useRoute from '@vl/hooks/useGbRouteDe';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import { useFormik } from 'formik';
import Link from '@uz/unitz-components-web/Link';
import * as Yup from 'yup';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            filterForm: hook((ctx) => {
              const route = useRoute();
              const locale = _.get(route.getPageContext(), 'lang', '');
              const routeParams = route.getParams();
              const search_data = _.get(routeParams, 'search', '');
              const status = _.get(routeParams, 'status', '');
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  status,
                  search: search_data,
                },

                onSubmit: async (values, actions) => {
                  try {
                    const memberFilterPagingModel = ctx.apply('REF.getRef', 'AccountPagingModel');
                    if (memberFilterPagingModel) {
                      await memberFilterPagingModel.onLoadPage(0);
                    }
                    route.navigate(
                      route.to(`/${locale}/accounts/me/course`, {
                        ..._.omit(form.values, ['']),
                      })
                    );
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: () => {
                  route.navigateExternal(route.to(`/${locale}/accounts/me/course`));
                },
              });

              return form;
            }),

            getFilterArgs: hook((ctx) => {
              const statusVars = {
                draft: 'draft',
                all: '',
              };
              return () => {
                const values = ctx.get('filterForm.values', {});
                const status = _.get(values, 'status', '');
                const args = {
                  search: _.get(values, 'search', ''),
                  ...(status ? { status: _.get(statusVars, status, '') } : {}),
                };
                if (status === 'all') _.unset(args, 'status');
                return args;
              };
            }),

            handleChangeSubmit: hook((ctx) => {
              return React.useMemo(() => _.debounce(() => ctx.apply('filterForm.handleSubmit'), 599), []);
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const dataQuery = `
                b2b_account(order_by: {created_at: desc_nulls_last}){
                    id
                    slug
                    account_profile {
                      id
                      display_name
                    }
                    owner {
                      id
                      profile {
                        avatar_url
                        display_name
                      }
                    }
                  }
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                  b2b_account_aggregate {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  // varsList: '$args: b2b_search_course_args!',
                  varsList: '',
                  getVars: () => {
                    return {
                      // args: ref.current.getFilterArgs(),
                    };
                  },
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              ctx.apply('REF.setRef', 'AccountPagingModel', pagination);
              return pagination;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolAccount.ListTable.name')}
                    </div>
                  ),
                  render: (val) => {
                    const name = _.get(val, 'account_profile.display_name') || _.get(val, 'owner.profile.display_name');
                    return (
                      <div className="identify-container">
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountDetail', val)}>
                          <div className="uppercase text-blue500 underline text-xs">
                            {name || ''}
                          </div>
                        </Link>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolAccount.ListTable.actions')}
                    </div>
                  ),
                  width: 500,
                  render: (val) => {
                    return (
                      <div className="flex text-xs text-center items-center space-x-2">
                        <Link className="text-blue500 hover:text-blue500">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_student')}
                        </Link>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourses'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblCourses');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
            statusOptions: hook(() => {
              const status = [
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'upcoming',
                  label: 'Upcoming',
                },
                {
                  value: 'ongoing',
                  label: 'Ongoing',
                },
                {
                  value: 'draft',
                  label: 'Draft',
                },
                {
                  value: 'completed',
                  label: 'Finished',
                },
              ];
              return status;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
